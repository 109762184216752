import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Hot Stamping",
  "description": "Applying heat to add a metal foil on the object surface.",
  "author": "Orkun Zafer Ozturk",
  "categories": ["mechanical"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["Silkscreen-Printing", "Hydrographics", "Powder-Coating"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hot stamping, or also known as foil printing, is done by applying heat according to the wanted design to a ribbon of metal foil onto the object's surface. Hot stamping applies to a very wide range of materials, including PVC, glass, ceramics, wood, and metals.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      